<template>
    <div class="height_100 py-4 " id="menu-bar-gpt">
        <div class="top w-100 pt-2">
            <div class="d-flex justify-space-between align-left mb-4 ml-4">
                <div class="logo pointer" @click="toUpGpt()"><img src="../assets/images/UPGPT.png" /></div>
                <!-- <v-btn class="" icon @click="toUpDay()">
                    <v-icon size="40">mdi-alpha-u-box</v-icon>
                </v-btn> -->
            </div>
            <v-divider class="mx-4"></v-divider>
            <v-list class="" :class="isToggle ? 'is-active' : ''" z-index='9999' dense>
                <v-list-item-group >
                        <v-list-item v-show='isAdmin=="true" || item.isAdmin == false' class="mb-3 pt-0 pb-2" style="height:50px" v-for="item in upMenuRouters" :key="item.name"  :to="item.path" >
                            <v-list-item-icon>
                                <div v-if='$route.path.includes(item.path)' class="vlist-border"></div>
                                <img class='vlist-icon-active pt-0 pb-0' height='30' v-if="checkLink(item) && item.normal == 'img-ainotebook'"  src="../assets/images/ainotebook.png" />
                                <img class='vlist-icon pt-0 pb-0 mt-1 mr-2' height='23' v-if="!checkLink(item) && item.normal == 'img-ainotebook'" src="../assets/images/ainotebook_dark.png" />
                                <img class='vlist-icon-active pt-0 pb-0' height='30'  v-if="checkLink(item) && item.normal == 'img'"  src="../assets/images/book1.png" />
                                <img class='vlist-icon pt-0 pb-0 mt-1' height='23' v-if="!checkLink(item) && item.normal == 'img'" src="../assets/images/book_dark.png" />
                                <v-icon class='vlist-icon-active' v-if="checkLink(item) && item.normal != 'img' && item.normal != 'img-ainotebook' " :size="item.normal.includes('fa-') ? 25:27">{{ item.active }}</v-icon>
                                <v-icon class='vlist-icon' v-if="!checkLink(item) && item.normal != 'img' &&  item.normal != 'img-ainotebook'" :size="item.normal.includes('fa-')? 23:25">{{ item.normal }}</v-icon> 
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="mt-2">{{ item.name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mx-4"></v-divider>
                        <v-list-item class="mb-3 pt-0 pb-2" style="height:50px" v-for="item in updayRouter" :key="item.name"  @click="toPage(item.name)" >
                            <v-list-item-icon>
                                <img v-if='item.name =="UpDay"' style='margin-left: 1.1rem;' height='30' src="../assets/images/upday_little_icon_100.png"/>
                                <img v-else-if='item.name =="UpChat"' style='margin-left: 1.1rem;' width='30' src="../assets/images/UpChat.svg"/>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="mt-2">{{ item.name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                </v-list-item-group>
                <div>
                </div>
            </v-list>
        </div>
        
        <div v-if="hideAvatar"></div>
        <div v-else-if="menuMember.userid != ''" >
                <!-- <div class="d-flex justify-left align-left mb-4 ml-4">
                    <div class="logo pointer" @click="toUpDay()"><img src="../assets/images/UPDAY.png" /></div>
                </div> -->
                <div class='d-flex justify-space-between align-center px-4 user_info' style='max-width:252.92px'>
                    <v-avatar color="aliceBlue" style="height: 36px;min-width: 36px;width: 36px;">
                        <span v-if="menuMember.pic === ''">{{ menuMember.nickname }}</span>
                        <img v-else :src="member.pic" :alt="menuMember.name" />
                    </v-avatar>
                    <div class="pl-1 pt-4 user_icon  mr-3" >
                        <span class='name ellipsis'>
                            {{menuMember.name}}
                        </span>
                        <p class='small_word ellipsis'>
                            {{menuMember.email}}
                        </p>
                    </div>
                    <div class="">
                        <v-btn v-hide-element='false' icon @click="onlogout()">
                            <v-icon>mdi-logout</v-icon>
                        </v-btn>
                    </div>
                </div>
        </div>        
        <v-dialog v-model='messageDialog' width='360' content-class='statusDialog' v-if='messageDialog'>
              <message-dialog @emitMessageDialog='onEmitMessageDialog' :message='message'></message-dialog >
        </v-dialog>
    </div>
</template>

<script>

    import { mapActions } from 'vuex';
    import PersonalSettingService from "@/services/PersonalSettingService";
    import passData from "@/services/passData.js";
    import i18n from '/common/plugins/vue-i18n.js';
    import messageDialog from '@/components/Dialog/messageDialog';

    export default {
        name: 'MenuBar',
        props: {
            member: { 
                type: Object 
            }
        },
        components: {
            messageDialog
        },
        data() {
            return {
                upMenuRouters: [
                    // {
                    // 	path: '/',
                    // 	name: 'Home',
                    // 	normal: 'icon-home-border',
                    // 	active: 'icon-home'
                    // },
                    // {
                    //     path: '/upgpt/Dashboard',
                    //     name: i18n.t('GPT_MENU.DASHBOARD'),
                    //     normal: 'icon-dashboard-border',
                    //     active: 'icon-dashboard',
                    // },
                    {
                        path: '/upgpt/Knowledges',
                        name: i18n.t('GPT_MENU.KNOWLEDGE'),
                        normal: 'img',
                        active: 'img',
                        isAdmin:true,
                    },
                    {
                        path: '/upgpt/Chatbot',
                        name: i18n.t('GPT_MENU.CHATBOT'),
                        normal: 'fas fa-robot',
                        active: 'fas fa-robot',
                        isAdmin:true,
                    },
                    {
                        path: '/upgpt/ChatHistory',
                        name: i18n.t('GPT_MENU.CHAT_HISTORY'),
                        normal: 'far fa-comment',
                        active: 'fas fa-comments',
                        isAdmin:true,
                    },
                    {
                        path: '/upgpt/UserManagement',
                        name: i18n.t('GPT_MENU.USER_MANAGEMENT'),
                        normal: 'fas fa-user-friends',
                        active: 'fas fa-users-cog',
                        isAdmin:true,
                    },
                    {
                        path: '/upgpt/UsageAnalysis',
                        name: i18n.t('GPT_MENU.USAGE_ANALYSIS'),
                        normal: 'mdi-chart-pie',
                        active: 'fas fa-chart-pie',
                        isAdmin:true,
                    },{
                        path: '/upgpt/Uploadrecord/',
                        name: i18n.t('GPT_MENU.UPLOADSTATUS'),
                        normal: 'mdi-folder-upload',
                        active: 'mdi-folder-upload',
                        isAdmin:true,
                    },{
                        path: '/upgpt/MeetingRecap/',
                        name: i18n.t('GPT_MENU.MEETINGRECAP'),
                        normal: 'mdi-clipboard-text',
                        active: 'mdi-clipboard-text',
                        isAdmin:false,
                    },
                    {
                        path: '/upgpt/AINotebook/',
                        name: i18n.t('GPT_MENU.AINOTEBOOK'),
                        normal: 'img-ainotebook',
                        active: 'img-ainotebook',
                        isAdmin:true,
                    },
                    {
                        path: '/upgpt/UserSetting/',
                        name: i18n.t('SETTING.PERSONALSETTING'),
                        normal: 'mdi-account',
                        active: 'mdi-account',
                        isAdmin:false,
                    },
                ],
                updayRouter:
                    [
                        // {
                        // path: '/',
                        // name: "UpDay",
                        // normal: 'icon-setting-w',
                        // active: 'icon-setting-b',
                        // },
                        {
                            name: 'UpChat',
                            normal: 'mdi-chat-processing-outline',
                            active: 'mdi-chat-processing',
                        },
                    ],
                downMenuRouter:
                {
                    path: '/Setting',
                    name: i18n.t('SETTING.TITLE'),
                    normal: 'icon-setting-w',
                    active: 'icon-setting-b',
                },
                offset: true,
                isMobile: false,
                isToggle: false,	
                newPic: null,
                userPic: false,
                newName: null,
                newAgentData: null,
                menuMember:{userid:''},
                memberData: null,
                statusColor: 'grey',
                messageDialog: false,
                message: '',
                upchat: this.$route.meta.upchat,
                hideAvatar: false,
                isUpchatUser: window.localStorage.getItem('isUpchatUser'),
                isAdmin: window.localStorage.getItem('is_staff'),
                showUpchatDashboard: false,
                hasUpchatLogin: false,
                screen_ratio_class_CIC:"",
                screen_ratio_class:"",

                user:JSON.parse(window.localStorage.getItem('user')),
            };
        },
        watch: {
            "member":async function () {
                if(this.member.userid != '')
                {
                    this.menuMember = this.member;
                    await this.getUserPhoto();
                }
            },
            "newPic": function () {
                this.menuMember.pic = this.newPic
            },
            "newName": function () {
                this.menuMember.name = this.newName.last_name+' '+this.newName.first_name;				
            },
            "newAgentData": function () {
                this.menuMember.is_service_agent = this.newAgentData.is_service_agent;
                this.menuMember.status = this.newAgentData.status;
            }
        },
        created() {
            if (window.localStorage.getItem('upchat_login') != null)
                this.hasUpchatLogin = window.localStorage.getItem('upchat_login') === 'true' ? true : false;
            this.menuMember = this.member;
            this.menuMember.email = this.user.email;
            let access_token = window.localStorage.getItem('access_token');
            if(access_token != null){
                this.getUserDetail();
            }
            window.addEventListener("message",(msg)=>{this.hide(msg.data)}, false);
            window.addEventListener('resize', () => {this.return_screen_class()})
            this.return_screen_class()
        },
        mounted() {
            
            passData.$on("menuBarPic", (e) => {
                this.newPic = e
            })
            passData.$on("menuBarName", (e) => {
                this.newName = e
            })
            passData.$on("menuBarAgentData", (e) => {
                this.newAgentData = e;
            })
            passData.$on("status", () => {
                if(this.menuMember.is_service_agent){
                    this.changeStatus('ONLINE');
                }
            })
        },
        methods: {
            return_screen_class(){
                let ratio = window.innerWidth/window.outerWidth;
                if(ratio <= 0.8){
                    this.screen_ratio_class_CIC = "bg-CIC mb-2 v-list-item-small";
                    this.screen_ratio_class = "mb-2 v-list-item-small" ;
                }else{
                    this.screen_ratio_class_CIC = "bg-CIC mb-2";
                    this.screen_ratio_class = "mb-2" ;
                }
            },
            remove_upchat_icon(){
                this.upMenuRouters = this.upMenuRouters.filter(el =>el.name != "UpChat" && el.name != i18n.t('UPCHAT.CIC'));
            },
            hide(msg){
                if(msg == 'hide Avatar'){
                    this.hideAvatar = true;
                }
            },
            // async beforeUnloadHandler(e, newStatus){
            //     // 
            //     await this.changeUserStatus(newStatus);
            //     // call API to update
            //     navigator.sendBeacon(`http://127.0.0.1:8000//tenant/demo_2/api/personalsetting/${this.memberData[0].id}/status/`, this.memberData[0])
            //     // await PersonalSettingService.updateStatus(this.memberData[0].id, this.memberData[0]);
            // },
            // async changeUserStatus(newStatus){
            //     // set memberData data to new status
            //     this.memberData[0].usersetting.status = newStatus;

            //     // set menuMember data to new status
            //     this.menuMember.status = newStatus;
                
            //     // set local storage data to new status
            //     let userJson = window.localStorage.getItem('user');
            //     let user = JSON.parse(userJson);
            //     user.status = newStatus;
            //     window.localStorage.setItem('user', JSON.stringify(user));
            // },
            async getUserDetail(){
                await PersonalSettingService.getCurrentUserDetail()
                .then(response => {
                    this.memberData = response.data;
                })
            },
            setLang(){
                let lang = window.localStorage.getItem('language');
                if(lang != 'zh_TW')
                {
                    window.location.reload();
                }
            },
            getUserPhoto(){
                if(this.userPic === false){
                    PersonalSettingService.getCurrentUserDetail()
                    .then(response => 
                    {
                        if(response.data[0].usersetting.photo != null)
                        {
                            this.menuMember.pic = response.data[0].usersetting.photo						
                            const USER = 'user';
                            let userJson = window.localStorage.getItem(USER);
                            let user = JSON.parse(userJson);
                            user.pic = response.data[0].usersetting.photo;
                            window.localStorage.setItem(USER, JSON.stringify(user));	
                            this.userPic = true
                        }
                        else{
                            this.menuMember.pic = '';
                        }
                        this.setLang()
                    })
                }
            },
            checkLink: function (item) {
                const windowsHref = this.$route.path;
                if(item.name == i18n.t('Bulk_Message.TITLE')){
                    if (windowsHref == '/Segments' || windowsHref == '/Campaigns'){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                else if (item.name == i18n.t('GENERAL.DASHBOARD')) {
                    if (windowsHref == '/DealDashboard' || windowsHref == '/CaseDashboard'){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                else if (item.name == i18n.t('GPT_MENU.KNOWLEDGE')) {
                    if (windowsHref == '/upgpt/Knowledges'){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                else{
                    if (windowsHref.includes(item.path))
                        return true;
                    else
                        return false;
                }
            },
            addDivider(item){
                if(item.name == i18n.t('Bulk_Message.TITLE')){
                    return true;
                }
                else if (item.name == i18n.t('COMPANY.TITLE')) {
                    return true;
                }
                else if (item.name == i18n.t('GENERAL.ACTIVITY')) {
                    return true;
                }
                else{
                    return false;
                }
            },
            toSegments(){
                if(this.$route.path !== '/Segments') this.$router.push('/Segments');
            },
            toCampaigns(){
                if(this.$route.path !== '/Campaigns') this.$router.push('/Campaigns');
            },
            toDealDashboard(){
                if(this.$route.path !== '/DealDashboard') this.$router.push('/DealDashboard');
            },
            toCaseDashboard(){
                if(this.$route.path !== '/CaseDashboard') this.$router.push('/CaseDashboard');
            },
            toUpChatDashboard(){
                let userJson = JSON.parse(window.localStorage.getItem('user'));
                if (userJson.upchat_dashboard != '') {
                    // if not login, put sso link to iframe src first
                    if (userJson.upchat_sso_link != '') {
                        window.open(userJson.upchat_dashboard, '_blank');
                    }
                }
            },
            toPage(val){
                if(val == "UpDay"){
                    this.toUpDay();
                }else{
                    this.toUpChatHost();
                }
            },
            toUpChatHost(){
                let userJson = JSON.parse(window.localStorage.getItem('user'));
                let upchat_login = JSON.parse(window.localStorage.getItem('upchat_login'));
                if (userJson.upchat_host != '') {
                    if (userJson.upchat_sso_link != '') {
                        if(upchat_login){
                           window.open(userJson.upchat_host, '_blank'); 
                        }else{
                            window.open(userJson.upchat_sso_link, '_blank');
                            window.localStorage.setItem('upchat_login', true);
                        }
                        
                    }
                }
            },
            toSetting(){
                this.$router.replace('/upgpt/UserSetting/')
            },
            toUpGpt(){
                if(this.$route.path !== '/upgpt/') this.$router.push('/upgpt/');
            },
            toUpDay(){
                if(this.$route.path !== '/') this.$router.push('/');
            },
            async onlogout() {
                this.messageDialog =  true;
                this.message = i18n.t("GENERAL.LOGOUT_MSG");
                // if(this.menuMember.is_service_agent){
                //     this.memberData[0].usersetting.status = 'OFFLINE';
                //     this.memberData[0].usersetting.default_value = JSON.parse(window.localStorage.getItem('user')).default_value
                //     await PersonalSettingService.updateDetail(this.memberData[0].id, this.memberData[0])
                // }
                // let tenant = window.localStorage.getItem('tenant');

                // await this.logout()
                // .then(() => {
                //     let redirectTo = (tenant != null || tenant != '') ? `/login?tenant=${tenant}` : '/login';
                //     this.menuMember = { userid: ''};
                //     this.userPic = false;
                //     this.$emit('logoutSuccess');
                //     this.$router.push(redirectTo);
                //     this.showUpchatDashboard = false;
                //     this.remove_upchat_icon();
                // })
            },
            // onResize: function () {
            //     if (window.innerWidth < 767) {
            //         this.isMobile = true;
            //     }
            // },
            toggleMenu() {
                if (this.isToggle == false) {
                    this.isToggle = true;
                } else {
                    this.isToggle = false;
                }
            },
            ...mapActions([
                'logout',
            ]),
            async changeStatus(newStatus){
                await this.getUserDetail();

                if(this.memberData != null){
                    this.memberData[0].usersetting.status = newStatus;

                    PersonalSettingService.updateDetail(this.memberData[0].id, this.memberData[0])
                    .then(response => {
                        // set menuMember data to new status
                        this.menuMember.status = response.data.usersetting.status;

                        // set local storage data to new status
                        let userJson = window.localStorage.getItem('user');
                        let user = JSON.parse(userJson);
                        user.status = response.data.usersetting.status;
                        window.localStorage.setItem('user', JSON.stringify(user));
                    })
                    .catch(response => {
                        console.log(response);
                    })
                }
            },
            async onEmitMessageDialog(val){
                this.messageDialog = false;
                if(val){
                    if(this.menuMember.is_service_agent){
                    this.memberData[0].usersetting.status = 'OFFLINE';
                    this.memberData[0].usersetting.default_value = JSON.parse(window.localStorage.getItem('user')).default_value
                    await PersonalSettingService.updateDetail(this.memberData[0].id, this.memberData[0])
                }
                let tenant = window.localStorage.getItem('tenant');

                await this.logout()
                .then(() => {
                    let redirectTo = (tenant != null || tenant != '') ? `/login?tenant=${tenant}&upgpt=Y` : '/login?upgpt=Y';
                    this.menuMember = { userid: ''};
                    this.userPic = false;
                    this.$emit('logoutSuccess');
                    this.$router.push(redirectTo);
                    this.showUpchatDashboard = false;
                    this.remove_upchat_icon();
                })
                }
            }
        }
    }
</script>